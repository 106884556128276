<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="distributor-container-mobile">
        <div class="category-header-mobile">
          <router-link to="/" class="breadcrubs-text-mobile"
            >{{ $t("mainpage") }} >
          </router-link>
          <router-link to="/didyouknow" class="breadcrubs-text-mobile">{{
            $t("footer-you-know")
          }}</router-link>
        </div>
      </div>
      <div class="items-container-mobile">
        <img
        v-if="this.post.images.some(image => image.type === 'Original')"
            :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${post.images[0].id}&width=1312&height=564&isKeepAspectRatio=true`"
          class="img-doyouknowproduct-mobile"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/image-default.png"
          class="img-loading-mobile"
          alt="Not loading"
        />

        <div v-html="this.post.htmlBody" class="style-container"></div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="distributor-container">
        <div class="category-header">
          <router-link to="/" class="breadcrubs-text"
            >{{ $t("mainpage") }} >
          </router-link>
          <router-link to="/didyouknow" class="breadcrubs-text">{{
            $t("footer-you-know")
          }}</router-link>
        </div>
      </div>
      <div class="items-container">
        <img
        v-if="this.post.images.some(image => image.type === 'Original')"
        :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${post.images[0].id}&width=1312&height=564&isKeepAspectRatio=true`"          class="big-img"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/image-default.png"
          class="img-loading"
          alt="Not loading"
        />
        <div v-html="this.post.htmlBody" class="style-container"></div>
        <!-- <router-link to="/categories/product" class="router-to-product"
          >Панеттоне классический 500г
        </router-link> -->
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import Header2 from "@/components/Header2.vue";

export default {
  name: "DidyouknowProduct",

  components: {
    Header2,
  },

  data() {
    return {
      post: {},
    };
  },

  methods: {
    getPost() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .get(`/api/Post/${this.$route.params.postId}`, {
          // pageNumber: this.currentPage,
          // pageSize: 100,
          headers: {
            'CurrentLanguage': locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.post = response.data;
          this.imgPathBase = process.env.VUE_APP_API_HOST;
        })
        .catch((error) => (this.error = error));
    },
  },
  created() {
    this.getPost();
  },
  watch: {
    currentLocale: {
      handler() {
        // Выполняйте запросы к API при изменении локали
        this.getPost(); // Передаем новую локаль в функцию для обновления данных
        // this.getCategory();    // Передаем новую локаль в другую функцию для обновления данных
      },
      immediate: true // Вызывать обработчик сразу после создания компонента
    }
  },
  computed: {
    currentLocale() {
      return  this.$i18n.locale; // Получаем текущую локаль из хранилища Vuex
    }
  }
};
</script>
  
  <style scoped>
.img-loading-mobile {
  max-width: fit-content;
  width: 100%;
}
.img-loading {
  max-width: 50%;
  width: 100%;
  margin: auto;
}
.style-container {
  max-width: 979px;
  width: 100%;
}
.big-img {
  width: 100%;
}
.horizontal-line-mobile {
  width: 100%; /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px; /* Высота полоски (можно изменить на ваше усмотрение) */
  max-width: 304px;
  margin-top: 52px;
  background: var(--04, #c37d46);
}
.title-name-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.horizontal-line {
  width: 100%; /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px; /* Высота полоски (можно изменить на ваше усмотрение) */
  max-width: 304px;
  margin-top: 52px;
  background: var(--04, #c37d46);
}
.title-name {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 44px;
  margin-bottom: 14px;
}
.router-to-product-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.item-description-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 19px;
  margin-bottom: 19px !important;
}
.item-title-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 19px !important;
}
.img-doyouknowproduct-mobile {
  width: 100%;
  /* margin-bottom: 23px; */
  height: 100%;
}
.breadcrubs-text-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.category-header-mobile {
  margin-top: 19px;
  margin-bottom: 33px;
}
.router-to-product {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 18px;
}
.item-description {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 39px;
  max-width: 975px;
}
.item-title {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 39px;
  max-width: 975px;
}
.breadcrubs-text {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.items-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 118px;
}
.category-header-mobile {
  margin-top: 19px;
  margin-bottom: 33px;
}

.category-title-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.distributor-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
}

.items-container {
  display: flex;
  /* margin-top: 20px;
  margin-bottom: 177px; */
  width: 1310px;
  /* padding: 12px; */
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  margin-bottom: 111px;
  flex-direction: column;
}

.category-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.distributor-container {
  display: flex;
  margin-top: 22px;
  margin-bottom: 22px;
  width: 1310px;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
}
</style>
  
  